import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import logo from '../../../../adamslogo.jpg'; // Adjust path as per your project structure



const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={1}
              flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box
                display={'flex'}
                component="a"
                href="/"
                title="Adams Lawncare and Landscaping - Goldsboro, NC"
                width={80}
            >
              <Box
                  component={'img'}
                  src={logo} // Using local logo image here
                  height={1}
                  width={1}
                  alt="Adams Lawncare and Landscaping - Goldsboro, NC"
              />
            </Box>
            <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
              <Box marginTop={1} marginRight={2}>
                <Link
                    underline="none"
                    component="a"
                    href="/"
                    color="text.primary"
                    variant={'subtitle2'}
                    title="Home - Adams Lawncare and Landscaping, Goldsboro, NC"
                >
                  Home
                </Link>
              </Box>
              <Box marginTop={1} marginRight={2}>
                <Link
                    underline="none"
                    component="a"
                    href="/team"
                    color="text.primary"
                    variant={'subtitle2'}
                    title="Meet Our Team - Lawncare and Landscaping Experts in Goldsboro, NC"
                >
                  Our Team
                </Link>
              </Box>
              <Box marginTop={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    component="a"
                    href="/contact"
                    size="small"
                    title="Contact Adams Lawncare and Landscaping, Goldsboro, NC"
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
              align={'center'}
              variant={'subtitle2'}
              color="text.secondary"
              gutterBottom
          >
            &copy; {new Date().getFullYear()} Adams Lawncare and Landscaping, Goldsboro, NC. All rights reserved.
          </Typography>
          <Typography
              align={'center'}
              variant={'caption'}
              color="text.secondary"
              component={'p'}
          >
            Adams Lawncare and Landscaping proudly serves residential and commercial properties in Goldsboro, NC. Our services include lawn care, landscaping, garden maintenance, and more.
          </Typography>
          <Typography
              align={'center'}
              variant={'caption'}
              color="text.secondary"
              component={'p'}
          >
            By using our website, you agree to the use of cookies for a faster, safer experience and for marketing purposes. Contact us today for all your lawncare and landscaping needs in Goldsboro, North Carolina.
          </Typography>
        </Grid>
      </Grid>
  );
};

export default Footer;