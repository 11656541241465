import React, { useState } from 'react';
import Box from '@mui/material/Box';

const CoverSlider = () => {
    const [loaded, setLoaded] = useState(false);

    return (
        <Box
            sx={{
                height: { xs: 'auto', md: 1 },
                '& img': {
                    objectFit: 'cover',
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.5s ease-in-out', // Smooth transition when the image loads
                },
            }}
        >
            {/* Placeholder image while the main image is loading */}
            {!loaded && (
                <Box
                    component={'img'}
                    src={"https://via.placeholder.com/800x400?text=Loading..."}
                    height={{ xs: 'auto', md: 1 }}
                    maxHeight={{ xs: 300, md: 1 }}
                    width={1}
                    maxWidth={1}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                    }}
                />
            )}

            {/* Main image with lazy loading */}
            <Box
                component={'img'}
                src={"https://i.ibb.co/Pts9X7j/IMG-4575-copy.png"}
                height={{ xs: 'auto', md: 1 }}
                maxHeight={{ xs: 300, md: 1 }}
                width={1}
                maxWidth={1}
                loading="lazy"
                onLoad={() => setLoaded(true)} // Update state when image is loaded
            />
        </Box>
    );
};

export default CoverSlider;