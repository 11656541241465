import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Page from "./components/Page";
import Service from './views/Service/Service';
import React from 'react';
import ContactUs from './views/ContactPage';
function App() {
  return (
    <Page>
      <Router>
          <Routes>
              <Route path="/" element={<Service />} />
              <Route path={"/contact"} element={<ContactUs />} />
              {/*<Route path={"/products"} element={<Pricing />} />*/}
          </Routes>

      </Router>
    </Page>
  );
}

export default App;