/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const img2 = require('../../../../img1.png'); // Using require to load image

const InfoWithImage2 = () => {
    const theme = useTheme();

    return (
        <Grid container spacing={4}>
            {/* Text on the left */}
            <Grid item container alignItems={'center'} xs={12} md={6}>
                <Box sx={{ paddingTop: 3 }}> {/* Adding padding here */}
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            Professional Lawncare & Landscaping in Goldsboro, NC
                        </Typography>
                    </Box>
                    <Box marginBottom={3}>
                        <Typography variant="h6" component="p" color="text.secondary">
                            At Adams Lawncare and Landscaping, we specialize in enhancing the beauty of your outdoor spaces in Goldsboro, NC. Whether it's routine lawn care or a complete landscape redesign, our expert team delivers tailored solutions to fit your residential or commercial needs. Let us transform your yard into a space you'll love.
                        </Typography>
                    </Box>
                    <Box marginBottom={3}>
                        <Typography variant="h6" component="p" color="text.secondary">
                            Our services include lawn mowing, mulching, trimming, garden design, and outdoor living area creation. We handle all aspects of lawncare and landscaping so you can focus on enjoying your outdoor space. Proudly serving Goldsboro and nearby communities, we help homeowners and businesses elevate their landscapes.
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            {/* Image on the right */}
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        maxWidth: 450,
                        position: 'relative',
                        marginX: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-start', // Align image to the left without extra space
                        paddingRight: 0, // Remove right padding to reduce space
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            borderRadius: '2.75rem',
                            boxShadow: 1,
                            width: '75% !important',
                        }}
                    >
                        <Box>
                            <Box
                                position={'relative'}
                                top={'2.4%'}
                                width={'100%'}
                                height={'96%'}
                            >
                                <img
                                    src={img2}
                                    alt="Lawncare and Landscaping Services in Goldsboro, NC"
                                    style={{
                                        width: '150%', // Ensure the image fills the container without extra margin
                                        height: 'auto', // Adjust height automatically based on width
                                        objectFit: 'cover', // Crop the image
                                        objectPosition: 'center bottom', // Focus on the center of the image
                                        maxWidth: '500px', // Limit the max width of the image to make it smaller
                                        borderRadius: '10px', // Optional: Add some border-radius to match the design
                                        marginRight: 0, // Remove extra margin on the right side
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InfoWithImage2;