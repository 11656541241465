import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';

const CtaSection = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box>
            <Typography
                sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                }}
                gutterBottom
                color={'text.secondary'}
            >
                Landscaping
            </Typography>
            <Box marginBottom={2}>
                <Typography
                    variant="h2"
                    color="text.primary"
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    Transforming Outdoor Spaces with{' '}
                    <Typography
                        color={'primary'}
                        component={'span'}
                        variant={'inherit'}
                        sx={{
                            background: `linear-gradient(180deg, transparent 82%, ${alpha(
                                theme.palette.secondary.main,
                                0.3,
                            )} 0%)`,
                        }}
                    >
                        Expert Care
                    </Typography>
                </Typography>
            </Box>
            <Box marginBottom={3}>
                <Typography variant="h6" component="p" color="text.secondary">
                    Our mission is to help you enjoy your outdoor spaces without the hassle.
                    From lawn care to landscape design, let us handle the details while you
                    relax and enjoy the beauty of your yard.
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={isMd ? false : true}
                    href={'/contact'}
                >
                    Get a quote
                </Button>
            </Box>
        </Box>
    );
};

export default CtaSection;
