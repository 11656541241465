import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import Logo from '../../../../adamslogo.jpg';  // Import the PNG logo

import { NavItem } from './components';

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  logoImage: {
    height: '80px',  // Adjust logo height as needed
    width: 'auto',
  },
});

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const classes = useStyles();

  return (
      <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
      >
        <Box
            display={'flex'}
            component="a"
            href="/"
            title="Skilled Strategies"
            className={classes.logo}
        >
          <img src={Logo} alt="Skilled Strategies Logo" className={classes.logoImage} />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
          <Box marginLeft={4}>
            <Button
                variant="contained"
                color="primary"
                component="a"
                target="blank"
                href="/contact"
                size="large"
            >
              Get a Quote
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
          <Button
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={'outlined'}
              sx={{
                borderRadius: 2,
                minWidth: 'auto',
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
              }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;