import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
    About,
    InfoWithImage2,
    Features,
    Team,
    VideoSection,
    Hero
} from './components';

const Service = () => {
    const theme = useTheme();
    return (
        <Main>
                <Container position={'relative'}>
                    <Hero />
                </Container>
            <Container>
                <VideoSection />
            </Container>
            {/*<Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>*/}
            {/*    <Container>*/}
            {/*        <Advantages />*/}
            {/*    </Container>*/}
            {/*</Box>*/}
            <Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>
                <Container>
                    <Features/>
                </Container>
            </Box>

            <Container>
                <InfoWithImage2 />
            </Container>
            <Divider />
            {/*<Container>*/}
            {/*    <Reviews />*/}
            {/*</Container>*/}
            {/*<Container paddingTop={'0 !important'}>*/}
            {/*    <Integrations />*/}
            {/*</Container>*/}
            <Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>
                <Container>
                    <Team />
                </Container>
            </Box>
        </Main>
    );
};

export default Service;